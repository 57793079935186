// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import campaignsReducer from './campaigns'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  campaignsReducer
})

export default rootReducer
