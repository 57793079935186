const initialState = {
  id: null
}

const campaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CAMPAIGN_ID':
      return { ...state, id: action.payload }
      
    default:
      return state
  }
}

export default campaignsReducer
